import {Routes} from '@angular/router';
import {clientIdResolver} from '@utility/presentation/resolver/client-id.resolver';
import {clientDetailsResolver} from '@client/resolver/client.details.resolver';

export const routes: Routes = [
    {
        path: 'lounge',
        loadComponent: () => import('@utility/presentation/pages/lounge'),
    },
    {
        path: 'redirect',
        loadComponent: () => import('@utility/presentation/pages/redirect'),
    },
    {
        path: 'inactive',
        loadComponent: () => import('@utility/presentation/pages/inactive'),
    },
    {
        path: 'sitemap',
        loadComponent: () => import('@page/sitemap/sitemap.page'),
    },
    {
        path: 'language',
        loadComponent: () => import('@page/language/language.page'),
    },
    {
        path: ':clientId',
        resolve: {
            clientId: clientIdResolver,
            client: clientDetailsResolver,
        },
        children: [
            {
                path: '',
                loadChildren: () => import('@client/index'),
            },
            {
                path: 'order',
                loadChildren: () => import('@order/index'),
            },
            {
                path: 'service',
                loadChildren: () => import('@service/index'),
            },
            {
                path: 'ai',
                loadChildren: () => import('@ai/index'),
            }
        ],
    }
];
