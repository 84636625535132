import {Component, OnInit, ViewEncapsulation,} from '@angular/core';
// import {initMediaQueryList} from '@src/module/utility/color-scheme';
import {RouterOutlet} from '@angular/router';
import {DetectLanguageComponent} from "@utility/presentation/component/detect-language/detect-language.component";

@Component({
    selector: 'app-root',
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    imports: [RouterOutlet, DetectLanguageComponent],
    template: `
        <app-detect-language-component/>
        <router-outlet/>
    `,
})
export class AppComponent implements OnInit {

    public ngOnInit(): void {
        // initMediaQueryList();
    }

}
