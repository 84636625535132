import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    LOCALE_ID,
    OnInit,
    PLATFORM_ID,
    ViewEncapsulation
} from "@angular/core";
import {DOCUMENT, isPlatformBrowser} from "@angular/common";
import {LanguageCodeEnum, LanguageRecord, LanguageSwitchLabelRecord} from "@utility/domain/enum";
import {Store} from "@ngxs/store";
import {ClientState} from "@client/state/client/client.state";
import {filter, firstValueFrom} from "rxjs";
import {is} from "@utility/checker";
import {IClient} from "@client/domain";
import {NavigationEnd, Router} from "@angular/router";
import {Reactive} from "@utility/cdk/reactive";
import {NGXLogger} from "ngx-logger";
import {DetectLanguageService} from "@utility/presentation/component/detect-language/detect-language.service";

@Component({
    selector: 'app-detect-language-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    template: `
        <!-- Announcement Banner -->
        @if (showBanner) {
            <div id="proposal-to-change-language-by-user-navigator" class="w-full bg-[#394351] py-4 fixed bottom-0 z-10">
                <div
                    class="px-4 mx-auto group flex justify-between max-w-md">
                    <div class="flex flex-col leading-none justify-center">
                        <p class="me-2 inline-block text-neutral-200">
                            {{ switchLanguageLabel }}
                        </p>
                        <p class="me-2 inline-block text-sm text-neutral-200/50">
                            {{ currentLanguageLabel }}
                        </p>
                    </div>
                    <div class="flex gap-4">
                        <a [href]="href" target="_self"
                           class="py-2 px-3 rounded-2xl bg-neutral-50/50 text-black cursor-pointer">
                            {{ languageName }}
                        </a>
                        <button role="button" (click)="close()"
                                class="flex items-center justify-center w-[40px] h-[40px] rounded-2xl transition-all hover:bg-neutral-50/50 hover:text-black">
                            <i class="bi bi-x-lg"></i>
                        </button>
                    </div>
                </div>
            </div>
        }
        <!-- End Announcement Banner -->
    `,
})
export class DetectLanguageComponent extends Reactive implements OnInit {

    private readonly localeId = inject(LOCALE_ID);
    private readonly document = inject(DOCUMENT);
    private readonly platformId = inject(PLATFORM_ID);
    private readonly store = inject(Store);
    private readonly router = inject(Router);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly ngxLogger = inject(NGXLogger);
    private readonly detectLanguageService = inject(DetectLanguageService);

    public showBanner = false;
    public realUsersLanguage = this.localeId;
    public languageName = '';
    public switchLanguageLabel = '';
    public currentLanguageLabel = '';
    public href = '';
    public readonly localStorageKey = 'hideDetectLanguageBanner';

    public ngOnInit() {

        if (isPlatformBrowser(this.platformId)) {

            this.router.events.pipe(this.takeUntil(), filter((event) => event instanceof NavigationEnd)).subscribe((navigationEnd) => {

                firstValueFrom(this.store.select(ClientState.item).pipe(filter(is.not_null<Required<IClient>>))).then(({
                                                                                                                           username,
                                                                                                                           businessSettings: {availableLanguages}
                                                                                                                       }) => {

                    this.detect(username, availableLanguages);

                })

            })

        }


    }

    public close() {

        if (isPlatformBrowser(this.platformId)) {

            const data = {
                hiddenAt: new Date().toISOString(),
            };

            localStorage.setItem(this.localStorageKey, JSON.stringify(data));

            this.showBanner = false;
            this.detectLanguageService.showBanner$.next(false);

        }

    }

    private detect(username: string, availableLanguages: string[]) {

        const rawData = localStorage.getItem(this.localStorageKey);

        if (rawData) {

            const {hiddenAt} = JSON.parse(rawData);

            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            if (yesterday.toISOString() < hiddenAt) {
                this.ngxLogger.info(`User has selected language, check Application:LocalStorage:${this.localStorageKey}`);
                return;

            }

            localStorage.removeItem(this.localStorageKey);

        }

        const languageCode = this.localeId[0] + this.localeId[1];

        const defaultView = this.document.defaultView;

        if (!defaultView) {
            this.ngxLogger.info(`defaultView: absent!`);
            return;
        }

        const useLangByNavigator = defaultView.navigator.language[0] + defaultView.navigator.language[1];

        if (!availableLanguages.includes(useLangByNavigator)) {
            this.ngxLogger.info(`Business don't have user's language ${useLangByNavigator}!`);
            return;
        }

        if (useLangByNavigator !== languageCode) {

            this.showBanner = true;
            this.detectLanguageService.showBanner$.next(true);
            this.realUsersLanguage = useLangByNavigator;
            this.languageName = LanguageRecord[this.realUsersLanguage as LanguageCodeEnum];
            this.switchLanguageLabel = LanguageSwitchLabelRecord[this.realUsersLanguage as LanguageCodeEnum];
            this.currentLanguageLabel = LanguageSwitchLabelRecord[languageCode as LanguageCodeEnum];
            const pathname = this.getStringAfterWord(defaultView.location.pathname, username);
            this.href = `/${this.getCorrectLocaleFor(this.realUsersLanguage)}` + `/${username}` + pathname;

            this.changeDetectorRef.detectChanges();

        }

    }

    private getStringAfterWord(str: string, word: string): string {
        const index = str.indexOf(word);
        if (index === -1) {
            return ""; // якщо слово не знайдено, повернути порожній рядок
        }
        return str.substring(index + word.length); // повертаємо підрядок після слова
    }

    private getCorrectLocaleFor(language: string) {
        switch (language) {
            case LanguageCodeEnum.en:
                return 'en-US';
            default:
                return language;
        }
    }

}
