import {ApplicationConfig, importProvidersFrom, provideExperimentalZonelessChangeDetection,} from '@angular/core';
import {provideRouter, withInMemoryScrolling} from '@angular/router';

import {
    provideClientHydration,
    withEventReplay,
    withI18nSupport,
    withNoHttpTransferCache
} from '@angular/platform-browser';
import {provideHttpClient, withFetch, withInterceptors} from '@angular/common/http';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {tokens} from "@src/token";
import {environment} from "@environments/environment";
import {Utility} from "@utility/index";
import {initializeApp, provideFirebaseApp} from "@angular/fire/app";
import {getAnalytics, provideAnalytics} from "@angular/fire/analytics";
import {provideStore} from "@ngxs/store";
import {AppState} from "@utility/state/app/app.state";
import {ClientState} from "@client/state/client/client.state";
import {SpecialistState} from "@specialist/state/member/specialist.state";
import {ServiceState} from "@service/state/service/service.state";
import {PaymentState} from "@module/payment/state/payment/payment.state";
import {OrderState} from "@order/state/order/order.state";
import {CartState} from "@order/state/cart/cart.state";
import {ChatState} from "@ai/state/chat/chat.state";
import {withNgxsReduxDevtoolsPlugin} from "@ngxs/devtools-plugin";
import {NgxsDispatchPluginModule} from "@ngxs-labs/dispatch-decorator";
import {withNgxsStoragePlugin} from "@ngxs/storage-plugin";
import {NgxsSelectSnapshotModule} from "@ngxs-labs/select-snapshot";
import {routes} from "@src/routers";
import {BusySlotsState} from "@module/slot/state";

export const appConfig: ApplicationConfig = {
    providers: [
        ...tokens,
        provideRouter(
            routes,
            withInMemoryScrolling({
                scrollPositionRestoration: 'enabled',
                anchorScrolling: 'enabled',
            })
        ),

        provideHttpClient(
            withInterceptors([
                // Utility.Interceptors.Approval, // TODO find way how to handle firebase network!
                // Utility.Interceptors.Loading,
                // Utility.Interceptors.Notification,
                // Utility.Interceptors.Error,

                Utility.Interceptors.PrepareLocalHeadersInterceptor,
                Utility.Interceptors.ApprovalInterceptor,
                Utility.Interceptors.ParamsReplaceInterceptor,
                Utility.Interceptors.LoadingInterceptor,
                Utility.Interceptors.NotificationInterceptor,
                Utility.Interceptors.ErrorInterceptor,
                Utility.Interceptors.SourceInterceptor,
                Utility.Interceptors.ClearLocalHeadersInterceptor,
            ]),
            withFetch()
        ),

        provideFirebaseApp(() => initializeApp(environment.firebase.options)),
        provideAnalytics(() => getAnalytics()),
        provideStore(
            [
                AppState,
                ClientState,
                SpecialistState,
                BusySlotsState,
                ServiceState,
                PaymentState,
                OrderState,
                CartState,
                ChatState,
            ],
            {
                developmentMode: !environment.production
            },
            withNgxsReduxDevtoolsPlugin({
                disabled: environment.production,
            }),
            withNgxsStoragePlugin({
                keys: [
                    CartState,
                    ChatState,
                ],
                afterDeserialize(obj: any, key: string): any {
                    // @ts-ignore
                    return {
                        [CartState.stateName]: CartState.afterDeserialize,
                    }[key]?.(obj) ?? obj;
                }
            }),
        ),
        importProvidersFrom(
            LoggerModule.forRoot({
                level: environment.production
                    ? NgxLoggerLevel.OFF
                    : NgxLoggerLevel.TRACE,
                serverLogLevel: NgxLoggerLevel.OFF,
            }),

            NgxsDispatchPluginModule.forRoot(),
            NgxsSelectSnapshotModule.forRoot(),
        ),
        provideClientHydration(
            withEventReplay(),
            withI18nSupport(),
            withNoHttpTransferCache()
        ),
        provideExperimentalZonelessChangeDetection()
    ],
};
