/// <reference types="@angular/localize" />

import {enableProdMode} from '@angular/core';
import {bootstrapApplication} from '@angular/platform-browser';
import {AppComponent} from '@app/app.component';
import {environment} from '@environments/environment';
import {appConfig} from "@app/app.config";

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, appConfig).catch((e) => console.error(e));
